.profile-popover .ant-popover-inner-content {
   background-color:black;
   padding: 0 !important;
 
}

.profile-popover .ant-popover-placement-bottom >
.ant-popover-placement-bottomLeft >
.ant-popover-placement-bottomRight {

    padding-left: 20px!important;
}

.profile-popover.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow{
    border-top-color: black !important;
    border-right-color: black !important;
    border-bottom-color:black !important;
    border-left-color:black !important;

}
.profile-popover p{
    margin-bottom:0px;
    margin-bottom:0px;

}

@media (max-width: 768px) and (min-width: 576px) {
    .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
            left:65% !important;
    }
    .ant-popover{
        z-index: 9999!important;
    }
}

@media (max-width: 576px) {
    .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
        left: 80%;
    }
    .ant-popover{
        z-index: 9999!important;
    }
}
