* {
    // font-family:'Cormorant Garamond'!important;;
    font-family: "Lato" !important;
}

body {
    @media (max-width: 768px) {
        // overflow-x: hidden !important;
    }
}

body,
p {
    font-size: 14px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: black;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
span,
ul,
li {
    // font-family: 'Noto Sans KR', Lato, sans-serif;
    font-weight: 400 !important;
}

input {
    font-weight: 400 !important;
}

a {
    text-decoration: none !important;
}

// @media (-webkit-device-pixel-ratio: 1.25) {
//     * {
//         zoom: 0.8;
//     }
// }
// @media (-webkit-device-pixel-ratio: 1.50) {
//     * {
//         zoom: 0.8;
//     }
// }
.root-wrapper {
    min-height: 100%;
}

.description-overlay {
    width: 80%;
    left: 5% !important;
}


/* ---------------------------- ANT DESIGN GLOBALS ----------------------------  */

.ant-empty {
    margin-top: 1rem;
    margin-bottom: 2rem;
}


/* Ant Modal */

.ant-modal-mask {
    background-color: rgba(38, 37, 37, 0.6);
}


/* Ant Select */

.ant-select-dropdown {
    font-size: 13px;
}

.ant-select-dropdown-menu-item:active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: rgba(0, 0, 0, 0.158) !important;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: rgba(0, 0, 0, 0.158) !important;
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: rgba(0, 0, 0, 0.158) !important;
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
    color: #ad9972;
}


/* Ant Cascader */

.ant-cascader-picker.scroll-cascader {
    padding: 0;
    border-radius: 0;
}

.ant-cascader-picker.scroll-cascader .ant-cascader-input.ant-input {
    position: static;
    width: 100%;
    background-color: transparent !important;
    border: 1px solid #e5e5e5;
    cursor: pointer;
    padding-right: 1.5rem;
    font-size: 14px;
    height: 45px;
    border-radius: 0;
}

.ant-cascader-picker.scroll-cascader .ant-cascader-picker-label {
    height: 100%;
    top: 0;
    margin-top: 0px;
    padding-top: 6px;
    font-size: 14px;
    font-weight: 500;
    color: #58514b;
    line-height: 35px;
}

.scroll-cascader .ant-cascader-picker-label:hover+.ant-cascader-input {
    box-shadow: none !important;
    border: 1px solid #e5e5e5;
}

.scroll-cascader.ant-cascader-picker:focus .ant-cascader-input,
.ant-input:focus {
    box-shadow: none !important;
    border: 2px solid #58514b;
    border-right-width: 2px !important;
}


/* Dropdown */

.ant-cascader-menus.scroll-cascader-menu {
    z-index: 1400 !important;
    max-width: 50% !important;
    overflow-x: scroll !important;
    border: 1px solid #e5e5e5;
    scrollbar-width: none;
}

.ant-cascader-menus.scroll-cascader-menu .ant-cascader-menu {
    min-width: 8rem !important;
}

.ant-cascader-menus.scroll-cascader-menu .ant-cascader-menu-item:hover {
    background: rgba(0, 0, 0, 0.158);
}

.ant-cascader-menus.scroll-cascader-menu::-webkit-scrollbar {
    -webkit-appearance: none;
}

.ant-cascader-menus.scroll-cascader-menu::-webkit-scrollbar:vertical {
    width: 0px;
}

.ant-cascader-menus.scroll-cascader-menu::-webkit-scrollbar:horizontal {
    height: 5px;
}

.ant-cascader-menus.scroll-cascader-menu::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.158);
    border-radius: 0px;
    border: 2px solid transparent !important;
}

.ant-cascader-menus.scroll-cascader-menu::-webkit-scrollbar-track {
    border-radius: 50%;
    background-color: transparent !important;
}

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
    background-color: #f5f5f5 !important;
    color: #58514b;
}

.ant-select-dropdown {
     ::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: none;
}

// .ant-select-disabled {
// 	:hover {
// 		cursor: default;
// 	}
// }
// ---------------- Image Component ----------------- //
.selected-collections-error-img,
selected-collections-loading-img {
    height: 100px;
    width: 100px !important;
    margin: 75px auto;
}

// Ant Empty Image // No search image icon
.no-search-image {
    .ant-empty-image {
        height: 70px;
    }
}

@mixin empty-image-card($height) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: $height;
    background-color: rgba(240, 240, 240, 0.2);
    border: 1px solid #eee;
    img {
        width: 65px !important;
        height: 60px !important;
    }
}

.slider-empty-image-card {
    @include empty-image-card(100%);
}

.gallery-listing-empty-image {
    @include empty-image-card(12rem);
}

.artwork-card-empty-image {
    @include empty-image-card(165px);
    width: 385px;
}

.artworks-listing-empty-image {
    @include empty-image-card(4rem);
    width: 6rem;
}

// ================================== Pagination ================================== //
.ant-pagination-total-text {
    font-size: 14px;
    font-weight: 500;
    color: #58514b;
}

.ant-pagination-item-active a,
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a,
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
    color: #58514b;
}

.ant-pagination-item-active,
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover,
.ant-pagination-item:focus,
.ant-pagination-item:hover {
    border-color: #000;
}

.ant-pagination-item-active,
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover,
.ant-pagination-item:focus {
    background-color: #000;
    color: #fff;
}

.ant-pagination-item-active a,
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a,
.ant-pagination-item:focus a {
    color: #fff;
}

.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
    color: #58514b;
    border-color: #58514b;
}

.ant-pagination-item,
.ant-pagination-item-link {
    border-radius: 50%;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: 20px;
    left: 0;
    text-align: left;
    padding-left: 15%;
    @media (max-width: 768px) {
        padding-left: 10%;
    }
}

.swiper-main-pagination {
    .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        line-height: 8px;
        opacity: 1;
        background-color: #e2e2e2;
    }
    .swiper-pagination-bullet-active {
        background-color: #d2502d;
    }
}

.swiper-custom-pagination {
    .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        line-height: 8px;
        opacity: 1;
        background-color: #e2e2e2;
    }
    .swiper-pagination-bullet-active {
        background-color: #000000;
    }
}