.cart-checkout-wrapper {}

.cart-top-button {
    display: flex;
    border-bottom: 1px #000 solid;
    padding-bottom: 15px;
    .back-btn {
        border: solid 1px #000;
        padding: 5px;
        border-radius: 3px;
        color: #000;
        display: flex;
        align-items: center;
        margin-right: 50px;
        height: 40px;
        cursor: pointer;
        img {}
        span {
            font-family: Lato !important;
            font-style: normal;
            font-weight: 600 !important;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 114% */
            text-align: center;
            text-transform: uppercase;
            color: #000000;
        }
    }
    .cart-nav-btn {
        cursor: pointer;
        font-family: Lato !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 24px;
        /* identical to box height, or 100% */
        text-align: center;
        color: #000000;
        margin: 12px 50px 0 0;
        opacity: 0.6;
    }
    .active {
        font-weight: normal !important;
        color: #000000;
        opacity: 1;
    }
}

.product-list-wrapper {
    margin-top: 15px;
    // height: 800px;
    // overflow-y: scroll;
    h2 {
        font-family: Lato !important;
        font-style: normal;
        font-weight: normal !important;
        font-size: 21px !important;
        line-height: 24px;
        /* identical to box height, or 114% */
        color: #515151;
    }
}

.name-container {
    display: flex;
    .info-btn {
        font-family: Lato !important;
        font-style: normal;
        font-weight: normal !important;
        font-size: 16px !important;
        line-height: 24px;
        /* identical to box height, or 150% */
        color: #000000;
        border: 0.5px solid #000000;
        box-sizing: border-box;
        border-radius: 4px;
        margin-left: 10px;
        cursor: pointer;
        height: 24px;
        padding: 2px;
        justify-content: center;
        display: flex;
        align-items: center;
    }
}

.price-container-cart {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
}

.refernace-container {
    @media only screen and (device-width: 768px) {
        /* for sumo sized (mac) screens */
        width: 200px;
    }
    @media only screen and (max-device-width: 480px) {
        /* styles for mobile browsers smaller than 480px; (iPhone) */
        width: 200px;
    }
    span {
        font-family: Lato !important;
        font-style: normal;
        font-weight: normal !important;
        font-size: 15px !important;
        line-height: 24px;
        /* identical to box height, or 133% */
        color: #000000;
    }
    p {
        opacity: 0.6;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 4px;
        font-family: Lato !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 18px !important;
        line-height: 24px;
        /* identical to box height, or 133% */
        color: #000000;
        opacity: 0.6;
        padding: 5px;
        @media only screen and (device-width: 768px) {
            /* for sumo sized (mac) screens */
            word-break: break-word;
        }
        @media only screen and (max-device-width: 480px) {
            /* styles for mobile browsers smaller than 480px; (iPhone) */
            word-break: break-word;
        }
    }
}

.cart-footer-container {
    display: flex;
    margin-top: 15px;
    .artist-details {
        display: flex;
        width: 350px;
        @media only screen and (device-width: 768px) {
            /* for sumo sized (mac) screens */
            width: 50%;
            flex-direction: column;
        }
        @media only screen and (max-device-width: 480px) {
            /* styles for mobile browsers smaller than 480px; (iPhone) */
            width: 50%;
            flex-direction: column;
        }
        img {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            margin-right: 20px;
        }
    }
    .artist-name-container {
        span {
            font-family: Lato !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-size: 12px !important;
            line-height: 24px;
            /* identical to box height, or 200% */
            color: #000000;
        }
        p {
            font-family: Lato !important;
            font-style: normal !important;
            font-weight: bold !important;
            font-size: 18px !important;
            line-height: 24px;
            /* identical to box height, or 133% */
            color: #000000;
        }
    }
}

.cart-product-details {
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    position: sticky;
    top: 180px;
    margin-bottom: 20px;
    @media only screen and (device-width: 768px) {
        /* for sumo sized (mac) screens */
        padding-left: 1px;
    }
    @media only screen and (max-device-width: 480px) {
        /* styles for mobile browsers smaller than 480px; (iPhone) */
        padding-left: 1px;
    }
    .cart-product-single {
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
        .actual-price {
            margin-right: 20px;
        }
        .offer-price {}
        h2 {
            font-family: Lato !important;
            font-style: normal;
            font-weight: 600 !important;
            font-size: 16px !important;
            line-height: 24px;
            /* identical to box height, or 133% */
            color: #000000;
            opacity: 0.6;
            margin-bottom: 5px;
        }
        h4 {
            font-family: Lato !important;
            font-style: normal;
            font-weight: bold !important;
            font-size: 22px !important;
            line-height: 24px;
            /* or 100% */
            color: #000000;
        }
    }
    .shipping-charge-box {
        border-bottom: 1px solid #515151;
        padding-bottom: 10px;
        margin-top: 20px;
        p {
            font-family: Lato !important;
            font-style: normal;
            font-weight: 600 !important;
            font-size: 16px !important;
            line-height: 24px;
            /* identical to box height, or 133% */
            color: #000000;
        }
    }
    .total-charge-box {
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        h2 {
            font-family: Lato !important;
            font-style: normal !important;
            font-weight: bold !important;
            font-size: 16px!important;
            line-height: 18px;
            /* identical to box height, or 100% */
            color: #000000;
            opacity: 0.6;
            margin-bottom: 20px;
        }
        .price-text {
            font-family: Lato !important;
            font-style: normal;
            font-weight: bold !important;
            font-size: 28px !important;
            line-height: 24px;
            margin-top: 10px;
            /* or 71% */
            color: #000000;
        }
        .total-charge-box1 {
            margin-right: 15px;
        }
    }
    .make-payment-btn {
        background: #000000;
        border-radius: 4px;
        width: 250px;
        height: 50px;
        font-family: Lato !important;
        font-style: normal;
        font-weight: bold !important;
        font-size: 20px !important;
        line-height: 24px;
        margin-top: 25px;
        /* identical to box height, or 100% */
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}

.action-wrapper {
    width: 100%;
}