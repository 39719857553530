.artist-menu-main-wrapper {
    display: flex;
    flex-direction: row;
    .vertical-line {
        height: 100%;
        width: 3px;
        background-color: #000000;
    }
    .artist-new-menu-main-container {
        margin: 10px 15px 0px 50px;
        width: 335px;
        // border-right: solid 2px black;
        padding-right: 15px;
        @media only screen and (max-device-width: 768px) {
            width: 98%;
            margin-right: 0px;
            margin-top: 85px;
            border: none;
            margin-left: 1px;
        }
        @media only screen and (max-device-width: 480px) {
            width: 98%;
            margin-right: 0px;
            margin-top: 85px;
            border: none;
            margin-left: 1px;
        }
        .artist-menu-sub-main-caontainer {
            background: #FFFFFF;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
            border-radius: 8px;
            margin: 10px 0px 15px 10px;
            // padding: 15px;
            cursor: pointer;
            .artist-menu-header-div {
                display: flex;
                align-items: center;
                cursor: pointer;
                // margin: 10px 0px;
                height: 45px;
                padding: 0 15px;
                img {
                    margin-right: 15px;
                    width: 15px;
                    height: 15px;
                }
                span {
                    font-family: 'Lato' !important;
                    font-style: normal !important;
                    font-weight: bold !important;
                    font-size: 13px !important;
                    line-height: 14px !important;
                    /* identical to box height */
                    text-transform: uppercase;
                    color: #000000;
                }
            }
            .artist-menu-child-menu-div {
                display: flex;
                flex-direction: column;
                padding-left: 45px;
                span {
                    cursor: pointer;
                    font-family: 'Lato' !important;
                    font-style: normal !important;
                    font-weight: 400 !important;
                    font-size: 13px !important;
                    line-height: 14px;
                    /* identical to box height */
                    color: #000000;
                    opacity: 0.6;
                    margin-bottom: 6px;
                }
                .active-sub-menu {
                    font-weight: 700 !important;
                }
            }
        }
    }
}