.checkout-section-wrapper {
    display: flex;
    flex-direction: column;
    .section-heading-text {
        font-family: Lato !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 24px;
        /* identical to box height, or 114% */
        color: #000000;
        margin-bottom: 20px;
    }
    .section-heading-text-repayment {
        font-family: Lato !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 24px;
        /* identical to box height, or 114% */
        color: #000000;
        margin-bottom: 5px;
    }
    .checkout-blockchain-wrapper {
        display: flex;
        flex-direction: column;
        margin: 15px 0;
        border-bottom: 1px solid #000000;
        padding-bottom: 15px;
        .blockchain-text {
            font-family: Lato !important;
            font-style: normal !important;
            font-weight: 400 !important;
            font-size: 14px !important;
            line-height: 24px;
            /* or 133% */
            color: #000000;
            opacity: 0.6;
            margin-bottom: 20px;
        }
        .blockchain-label {
            font-family: Lato !important;
            font-style: normal !important;
            font-weight: 600 !important;
            font-size: 16px !important;
            line-height: 22px;
            /* identical to box height, or 122% */
            color: #000000;
            opacity: 0.6;
        }
        .blockchain-textinput {
            opacity: 0.6;
            border: 1.5px solid #979797;
            box-sizing: border-box;
            border-radius: 3px;
            width: 55%;
            height: 40px;
            margin-right: 10px;
            padding: 5px;
            @media only screen and (device-width: 768px) {
                /* for sumo sized (mac) screens */
                width: 100%;
                margin-bottom: 10px;
            }
            @media only screen and (max-device-width: 480px) {
                /* styles for mobile browsers smaller than 480px; (iPhone) */
                width: 100%;
                margin-bottom: 10px;
            }
        }
        .blockchain-select-input {
            margin-bottom: 10px;
            opacity: 0.6;
            border: 1.5px solid #979797;
            box-sizing: border-box;
            border-radius: 3px;
        }
        .blockchain-textinput-container {
            display: flex;
            flex-direction: row;
            @media only screen and (device-width: 768px) {
                /* for sumo sized (mac) screens */
                flex-direction: column;
            }
            @media only screen and (max-device-width: 480px) {
                /* styles for mobile browsers smaller than 480px; (iPhone) */
                flex-direction: column;
            }
        }
        .or-text {
            font-size: 16px !important;
            font-family: Lato !important;
            font-style: normal !important;
            font-weight: bold !important;
            margin: 10px;
            @media only screen and (device-width: 768px) {
                /* for sumo sized (mac) screens */
                text-align: center;
            }
            @media only screen and (max-device-width: 480px) {
                /* styles for mobile browsers smaller than 480px; (iPhone) */
                text-align: center;
            }
        }
        .block-chain-verify-btn-wrapper {
            display: flex;
            justify-content: center;
            .block-chain-verify-btn {
                width: 210px;
                background-color: #000000;
                border-radius: 5px;
                height: 45px;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 15px;
                margin-bottom: 15px;
                cursor: pointer;
                font-family: Lato !important;
                font-style: normal !important;
                font-weight: 700 !important;
                font-size: 16px !important;
                line-height: 24px;
                text-transform: uppercase;
                span {
                    margin: 0 10px;
                }
            }
        }
    }
    .checkout-shipping-address-wrapper {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #000000;
        padding-bottom: 15px;
        margin: 15px 0;
        .shipping-heading-container {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
        }
        .billing-address-switch {
            display: flex;
            flex-direction: row;
            .form-switch {
                margin-left: 10px;
            }
        }
        .address-container {
            background: #f0f0f0;
            border-radius: 6px;
            padding: 10px 25px;
            margin: 20px 0;
            p {
                font-family: Lato !important;
                font-style: normal !important;
                font-weight: 400 !important;
                font-size: 14px !important;
                line-height: 28px;
                /* or 156% */
                color: #000000;
                opacity: 0.6;
            }
        }
    }
    .checkout-billing-address-wrapper {
        display: flex;
    }
}

.checkout-payment-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #000000;
    .payment-icon-container {
        display: flex;
        flex-direction: row;
        @media only screen and (device-width: 768px) {
            /* for sumo sized (mac) screens */
            overflow: scroll;
        }
        @media only screen and (max-device-width: 480px) {
            /* styles for mobile browsers smaller than 480px; (iPhone) */
            overflow: scroll;
        }
        .payment-btn {
            img {
                // width: 20px;
                // height: 20px;
                margin-bottom: 10px;
                margin-right: 5px;
            }
        }
    }
}

.checkout-icon-button {
    display: flex;
    flex-direction: column;
    background-color: #000000;
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 24px;
    /* or 150% */
    text-align: center;
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 40px;
    border-radius: 3px;
    padding: 0 10px;
    cursor: pointer;
    @media only screen and (device-width: 768px) {
        /* for sumo sized (mac) screens */
        font-size: 12px !important;
    }
    @media only screen and (max-device-width: 480px) {
        /* styles for mobile browsers smaller than 480px; (iPhone) */
        font-size: 12px !important;
    }
    img {
        width: 13px;
        height: 15px;
        margin-right: 10px;
    }
}

.disable-checkout-btn {
    pointer-events: none !important;
    opacity: 0.4;
}

.coupon-wrapper {
    display: flex;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #515151;
    flex-direction: column;
    margin-left: 50px;
    @media only screen and (device-width: 768px) {
        /* for sumo sized (mac) screens */
        margin-left: 0px;
    }
    @media only screen and (max-device-width: 480px) {
        /* styles for mobile browsers smaller than 480px; (iPhone) */
        margin-left: 0px;
    }
    .coupon-title-text {
        font-family: Lato !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 24px;
        color: #000000;
    }
    .coupon-text-container {
        display: flex;
        flex-direction: row;
        .btn-coupon {
            width: 35%;
            height: 40px;
            display: flex;
            font-family: Lato !important;
            font-style: normal !important;
            font-weight: bold !important;
            font-size: 16px !important;
            line-height: 24px;
            /* identical to box height, or 150% */
            text-align: center;
            color: #FFFFFF;
            background: #000000;
            border-radius: 4px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .txt-coupon {
            border: 1px solid #000000;
            box-sizing: border-box;
            border-radius: 4px;
            height: 40px;
            width: 60%;
            margin-right: 5%;
            opacity: 0.6;
            padding: 0 5px;
        }
    }
}

.card-payment-warpper {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    h4 {}
    .card-img-container {
        display: flex;
        flex-direction: row;
        img {
            margin-right: 10px;
        }
    }
}

.paypal-button-row {
    width: 100px !important;
}

.paynow-card-btn {
    width: 150px;
    background-color: #000000;
    border-radius: 5px;
    height: 45px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 24px;
    text-transform: uppercase;
}

.offer-strikethrough {
    position: absolute;
    width: 105.19px;
    height: 0px;
    border: 2px solid #FF0000;
    transform: rotate(7.6deg);
    left: 50px;
    top: 40px;
    @media only screen and (device-width: 768px) {
        /* default iPad screens */
        left: 0px;
    }
    @media only screen and (max-device-width: 480px) {
        /* styles for mobile browsers smaller than 480px; (iPhone) */
        left: 0px;
    }
}

.section-sub-head-text {
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px;
    /* identical to box height, or 114% */
    color: #000000;
    margin-bottom: 20px;
}

.coupon-code-text-success {
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px;
    /* identical to box height, or 150% */
    color: #027d1d;
    margin-top: 10px;
}

.coupon-code-text-failed {
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px;
    /* identical to box height, or 150% */
    color: #970808;
    margin-top: 10px;
}

.coupon-code-text {
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px;
    /* identical to box height, or 150% */
    color: #000000;
    margin-top: 10px;
}

.og-price {
    color: #e21941 !important;
    text-decoration: line-through;
    margin-right: 10px;
}

.price-section {
    display: flex;
}

.m-l-10 {
    margin-right: 10px;
}