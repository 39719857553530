#pagenotfound {
  .pnf-container {
    margin: 12rem auto;
    text-align: center;
  }
  .pnf-title {
    font-size: 2.5rem !important;
    font-family: Lato;
    line-height: 2.65rem;
    text-align: center;
  }
  .pnf-img {
    object-fit: contain;
    height: 33vh;
  }
  .pnf-btn-wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 2rem;
    justify-content: center;
    gap: 1.4rem;
  }
  .pnf-contactlink {
    padding-top: 3.5rem;
    font-size: 2rem !important;
  }
  .contact-link {
    font-weight: 400;
    color: #000000;
    cursor: pointer;
  }
  .pnf-btn {
    background: #000000;
    border: 1px solid #000000;
    box-sizing: border-box;
    color: #ffff;
    padding: 2rem 3.5rem;
    font-style: normal;
    font-weight: bold;
    line-height: 1rem;
    text-transform: uppercase;
    font-size: 1.5rem;
  }
}

@media (max-width: 1440px) {
  #pagenotfound {
    .pnf-container {
      margin: 3rem auto;
    }
    .pnf-title {
      font-size: 1.5rem !important;
    }
    .pnf-contactlink {
      font-size: 1.2rem !important;
    }
    .pnf-img {
      height: 30vh;
    }
    .pnf-btn {
      padding: 0.7rem 1.3rem;
      font-size: 0.9rem;
    }
  }
}

@media (max-width: 1024px) {
  #pagenotfound {
    .pnf-container {
      margin: 2rem auto;
    }
    .pnf-title {
      font-size: 1.2rem !important;
    }
    .pnf-contactlink {
      font-size: 1rem !important;
    }
    .pnf-img {
      height: 28vh;
    }
    .pnf-btn {
      padding: 0.5rem 1rem;
      font-size: 0.7rem;
    }
  }
}

@media (max-width: 768px) {
  #pagenotfound {
    .pnf-container {
      margin: 1.7rem auto;
    }
    .pnf-title {
      font-size: 1rem !important;
    }
    .pnf-contactlink {
      font-size: 0.9rem !important;
    }
    .pnf-img {
      height: 25vh;
    }
    .pnf-btn {
      padding: 0.5rem 1rem;
      font-size: 0.6rem;
    }
  }
}

@media (max-width: 425px) {
  #pagenotfound {
    .pnf-container {
      margin: 3rem auto;
    }
    .pnf-title {
      font-size: 0.8rem !important;
    }
    .pnf-contactlink {
      font-size: 0.65rem !important;
    }
    .pnf-img {
      height: 20vh;
    }
    .pnf-btn {
      padding: 0.3rem 0.8rem;
      font-size: 0.5rem;
    }
  }
}
