.top-first-content-wrap {
    display: flex;
    justify-content: flex-start;
    width: 50%;
}

.top-first-content-wrap span {
    /* text-align: left;
    font: normal normal 600 13px/24px Lato !important;
    letter-spacing: 0.13px;
    color: #FFFFFF; */
    letter-spacing: 0.13px;
    color: rgb(255, 255, 255);
    font: 600 15px / 24px Lato !important;
    margin: 0 10px;
}

.top-second-content-wrap {
    display: flex;
    justify-content: flex-end;
    width: 50%;
}

.indication {
    border: solid 1px red;
}

.language-wrapper {
    width: 50%;
    /* background: aqua; */
    display: flex;
    justify-content: space-evenly;
    border-right: solid 1px #cccccc;
}

.currency-wrapper {
    width: 50%;
    /* background: aqua; */
    display: flex;
    justify-content: space-evenly;
}

.buyer-link {
    padding: 0 15px;
    border-right: solid 1px #3e3e3e;
}

.seller-link {
    padding: 0 15px;
    border-right: solid 1px #3e3e3e;
}


/* Mobile part */

.first-head-wrapper {
    height: 65px;
}