.new-artwork-card {
    margin: 0.5rem;
    padding-left: 1rem;
    padding-top: 1rem;
    max-width: 450px;
    background: #ffffff;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    .artwork-card-action {
        display: flex;
        img {
            width: 25px !important;
            height: 25px;
            margin: 2px;
        }
    }
    .new-main-img {
        width: 380px;
        height: 300px;
        object-fit: contain;
        margin: 5px 15px 10px 0px;
        // background-color: #E4E4E4;
        padding: 10px;
    }
    .aution-artist-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        .follow-btn {
            position: absolute;
            right: 10%;
            color: #636363;
            border: 1px solid rgba(0, 0, 0, 0.34);
            box-sizing: border-box;
            border-radius: 4px;
            padding: 2px 5px;
            cursor: pointer;
        }
        img {
            width: 40px;
            height: 40px;
            margin: 5px 10px 5px 5px;
            object-fit: cover;
            border-radius: 50%;
            cursor: pointer;
        }
        h3 {
            font-family: Lato !important;
            font-style: normal !important;
            font-weight: bold !important;
            font-size: 15px !important;
            line-height: 16px;
            letter-spacing: 0.02em;
            color: #636363;
            margin-bottom: 5px;
            max-width: 150px;
            cursor: pointer;
        }
        p {
            margin-bottom: 2px;
            font-family: Lato !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-size: 12px !important;
            line-height: 12px;
            color: #a9a9a9;
        }
    }
    .new-artwork-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 90%;
        position: relative;
        .new-artwork-title {
            cursor: pointer;
            h3 {
                font-family: Lato !important;
                font-style: italic !important;
                font-weight: 300 !important;
                font-size: 16px !important;
                line-height: 19px;
                color: #000000;
            }
            p {
                font-family: Lato !important;
                font-style: normal !important;
                font-weight: 300 !important;
                font-size: 13px !important;
                line-height: 16px;
                color: #000000;
            }
        }
        .new-artwork-icon {
            display: flex;
            flex-direction: row;
            align-items: center;
            img {
                width: 30px;
                height: 40px;
                margin-right: 5px;
            }
        }
    }
    .auction-lot-pricing-container {
        display: flex;
        flex-direction: row;
        width: 80%;
        // justify-content: space-between;
        align-items: center;
        .date-area {
            // margin: 0 5px;
        }
        .price-area {
            margin-right: 15px;
        }
        .lot-label {
            font-family: 'Lato' !important;
            font-style: normal !important;
            font-weight: 300 !important;
            font-size: 13px !important;
            line-height: 15px;
            color: #000000;
            opacity: 0.6;
        }
        p {
            color: #000000;
        }
    }
    .new-price-details-wrapper {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        // justify-content: space-between;
        .price-div-2 {
            align-items: flex-end;
        }
        .aution-price-section {
            width: 45%;
            display: flex;
            flex-direction: row;
            p {
                font-family: Roboto !important;
                font-style: normal !important;
                font-weight: 300 !important;
                font-size: 13px !important;
                line-height: 15px;
                color: #636363;
            }
            h3 {
                font-family: Lato !important;
                font-style: normal !important;
                font-weight: normal !important;
                font-size: 13px !important;
                line-height: 16px;
                color: #000000;
            }
        }
    }
    @media (max-width: 768px) {
        .new-main-img {
            width: 320px;
            height: 280px;
            object-fit: contain;
            margin: 5px 15px 10px 0px;
            // background-color: #E4E4E4;
            padding: 10px;
        }
    }
}

.view-digital-certificate-btn {
    width: 100%;
    height: 50px;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
}