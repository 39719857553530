.signature-container {
    padding: 5px !important;
    .image-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        img {
            width: 200px;
            height: 200px;
            object-fit: cover;
            border: solid 0.5px gray;
            margin-bottom: 15px;
        }
        .close-icon {
            position: absolute;
            top: -7px;
            right: -7px;
        }
        .edit-icon {
            font-size: 27px;
            padding: 15px;
            background-color: rgba(218, 218, 218, 0.56);
            border-radius: 50%;
        }
        .edit-icon-container {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            font-size: 13px;
            padding: 0 1rem;
            text-align: center;
            height: 100px;
            width: 100px;
        }
    }
    .button-container {
        margin: 10px 0;
        border-top: 1px solid #000;
        padding-top: 25px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        .submit-button {
            background: #080808;
            letter-spacing: .015em;
            color: #fff;
            border-radius: 4px;
            margin-left: 15px;
            padding: 2px 10px;
        }
        .cancel-button {
            font-family: 'Lato' !important;
            font-style: normal;
            font-weight: 400 !important;
            font-size: 13px;
            line-height: 136%;
            /* identical to box height, or 18px */
            text-align: center;
            color: #000000;
            background: no-repeat;
            border: none;
            margin: 0 10px;
        }
    }
}