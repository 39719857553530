.digital-maincontainer {
    background: #fff;
    border-radius: 8px;
    padding: 30px;
    h4 {
        font-family: Lato!important;
        font-style: normal!important;
        font-weight: 400!important;
        font-size: 18px!important;
        line-height: 136%;
        color: #000;
        padding-bottom: 10px;
        border-bottom: 1px grey solid;
    }
    .form-group {
        margin: 20px 0;
        p {
            font-family: Lato!important;
            font-style: normal!important;
            font-weight: 700!important;
            font-size: 14px!important;
            line-height: 13px;
            letter-spacing: .015em;
            opacity: .34;
            color: #000;
            margin-bottom: 5px;
        }
        input {
            width: 100%;
            background: #f3f3f3;
            border-radius: 4px;
            height: 45px;
            border: none;
            padding: 0 10px;
        }
    }
    .button-container {
        margin: 10px 0;
        border-top: 1px solid #000;
        padding-top: 25px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        a {
            font-family: Lato!important;
            font-style: normal!important;
            font-weight: 400!important;
            font-size: 16px!important;
            line-height: 136%;
            text-align: center;
            color: #000;
        }
        button {
            background: #080808;
            letter-spacing: .015em;
            color: #fff;
            border-radius: 4px;
            margin-left: 15px;
        }
        .done-btn {
            align-self: center;
        }
    }
    .success-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
            font-family: 'Roboto' !important;
            font-style: normal;
            font-weight: 300;
            font-size: 15px !important;
            line-height: 18px;
            color: #000000;
            padding: 20px 10px;
        }
    }
    .button-container-cancel {
        margin: 10px 0;
        border-top: 1px solid #000;
        padding-top: 25px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        button {
            background: #080808;
            letter-spacing: .015em;
            color: #fff;
            border-radius: 4px;
            margin-left: 15px;
        }
    }
    .error {
        color: red;
        margin: 5px 0;
    }
    .welcome-page {
        p {
            font-family: 'Roboto' !important;
            font-style: normal !important;
            font-weight: 300 !important;
            font-size: 13px !important;
            line-height: 18px;
            color: #000000;
        }
        a {
            color: #333CFF !important;
        }
        .digital-sign-img-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .button-container {
            .yes-btn {
                background: #080808;
                letter-spacing: .015em;
                color: #fff;
                border-radius: 4px;
                margin-left: 15px;
                padding: 2px 10px;
            }
            .no-btn {
                font-family: 'Lato' !important;
                font-style: normal;
                font-weight: 400 !important;
                font-size: 13px;
                line-height: 136%;
                /* identical to box height, or 18px */
                text-align: center;
                color: #000000;
                background: no-repeat;
                border: none;
                margin: 0 10px;
            }
        }
    }
}