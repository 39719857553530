@media screen and (max-width: 768px) {
    .CookieConsent span{
        font-size:12px;
    }
    .CookieConsent div{
        margin: 5px!important;
    }
    #rcc-confirm-button{
        margin: 5px!important;

    }
}


