html,
body,
#root {
	margin: 0;
	height: 100%;
	font-family:'Cormorant Garamond',"Montserrat", sans-serif;
}
h2, h3, h4, h5, h6 {
    color: #353535;
    font-size: 25px;
}
a,img,i,button{
	cursor:pointer!important;
}
h1,
h2,
h3 {
	font-family: "Cormorant Garamond", sans-serif;
}
button,
 {
	font-family: "Montserrat", sans-serif !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none !important;
	margin: 0 !important;
}
input[type="number"] {
	-moz-appearance: textfield !important;
}
