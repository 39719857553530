.ant-message {
    color: #fafafa;
}
.ant-message .anticon {
    font-size: 20px;
}
.ant-message-notice-content {
    padding: 12px 20px;
    background: #58514B;
    min-width: 5rem;
    /* text-align: left; */
    font-weight: 500;
}
.ant-message .ant-message-custom-content {
    display: flex;
}

.ant-message .ant-message-custom-content img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}
