.main-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
}

.main-welcome-head {
    text-align: center;
    font: normal normal 300 25px/59px Lato;
    font-weight: 300 !important;
    letter-spacing: 0.63px;
    color: #000000;
    opacity: 1;
    padding-top: 40px;
    margin-bottom: 15px;
}

.artist-name {
    text-align: center;
    font: normal normal 900 20px/25px Lato;
    font-size: 20px !important;
    font-weight: 900 i !important;
    letter-spacing: 0px;
    color: #434343;
    text-transform: uppercase;
    opacity: 1;
}

.warning-wrapper {
    display: flex;
    align-items: center;
}

.warning-wrapper img {
    width: 20px;
    height: 20px;
}

.warning-wrapper p {
    text-align: left;
    font: normal normal normal 14px/17px Lato;
    letter-spacing: 0.35px;
    color: #ff0000;
    opacity: 1;
    margin-bottom: 2px;
    margin-left: 5px;
}

.card-main-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    justify-content: center;
}

.card-wrapper {
    width: 30%;
    /* height: 171px; */
    background: #f6f6f8 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    margin: 10px;
    display: flex;
    padding-bottom: 10px;
}

.card-wrapper:hover {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 24px #00000029;
    border-radius: 6px;
}

.card-link-wrapper {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.card-img-icon {
    width: 47px;
    height: 47px;
    margin: 10px;
}

.card-link-wrapper h1 {
    text-align: left;
    font: normal normal bold 17px/23px Lato;
    letter-spacing: 0.42px;
    color: #000000;
    font-weight: bold !important;
    margin-top: 10px;
    margin-bottom: 10px;
}

.card-link-wrapper a {
    text-align: left;
    font: normal normal normal 15px/30px Lato;
    letter-spacing: 0.38px;
    color: #0a75c8;
}

.card-link-wrapper a:hover {
    text-align: left;
    font: normal normal normal 15px/30px Lato;
    letter-spacing: 0.38px;
    color: black;
}

@media screen and (max-width: 769px) {
    .card-wrapper {
        width: 100%;
    }
}


/* New CSS fro new design */

.artist-dashboard-main-wrappper {
    display: flex;
    flex-direction: row;
    @media only screen and (max-device-width: 768px) {
        flex-direction: column-reverse;
    }
    @media only screen and (max-device-width: 480px) {
        flex-direction: column-reverse;
    }
    .artist-content-container {
        width: 100%;
        margin: 15px;
        @media only screen and (max-device-width: 768px) {
            width: 99%;
            margin: 1px;
        }
        @media only screen and (max-device-width: 480px) {
            width: 99%;
            margin: 1px;
        }
        .profile-new-container {
            height: 100px;
            width: 200px;
            position: absolute;
            bottom: -85px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            @media only screen and (max-device-width: 768px) {
                bottom: -75px;
                width: 99%;
                margin: 1px;
            }
            @media only screen and (max-device-width: 480px) {
                bottom: -75px;
                width: 99%;
                margin: 1px;
            }
            h1 {
                text-align: center;
                font: normal normal 500 24px/30px Lato !important;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
                @media (max-width: 768px) {
                    font: normal normal 300 20px/23px Lato !important;
                }
            }
            img {
                border-radius: 50%;
                height: 150px;
                width: 150px;
                border: solid 1px #ffffff;
                @media only screen and (max-device-width: 768px) {
                    height: 80px;
                    width: 80px;
                }
                @media only screen and (max-device-width: 480px) {
                    height: 80px;
                    width: 80px;
                }
            }
        }
    }
}