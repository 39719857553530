
	.add-kyc-content {
		width: 37rem;
		font-size: 15px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.add-kyc-body {
			display: flex;
			flex-direction: column;
			width: 90%;
			align-items: center;
			// margin-bottom: 1rem;
			.upload-box {
				display: flex;
				background-color: #58514b;
				flex-direction: column;
				width: 9rem;
				height: 9rem;
				justify-content: center;
				align-items: center;
				border: 1px solid #cdcdcd;
				border-radius: 3px;
				margin-bottom: 1rem;
				font-size: 13px;
				color: #fff;
				font-weight: 500;
				cursor: pointer;
				img.image-upload {
					margin-bottom: 10px;
				}
				img.media-thumb {
					max-width: 9rem;
					max-height: 9rem;
					object-fit: fill;
					min-height: 9rem;
					min-width: 9rem;
				}
			}
			.bg-white {
				background-color: #fff;
			}
			.form-control {
				width: 100%;
				display: flex;
				flex-direction: column;
				input {
					width: 100%;
				}
			}
		}
		.add-kyc-featured-box {
			display: flex;
			width: 90%;
			margin-bottom: 1rem;
			justify-content: space-between;
			font-size: 14px;
			font-weight: 500;
			color: rgba(0, 0, 0, 0.85);
			padding: 0 5px;
			flex-direction: column;
			.section {
				h6 {
					color: #747474;
					font-size: 14px;
					font-weight: 400;
				}

				h4 {
					color: #000000;
					font-size: 16px;
				}
			}
			.section-image {
				display: flex;
				flex-direction: row;
				justify-content: space-around;
				margin-bottom: 20px;

				.image-container {
					margin-right: 15px;
					align-items: center;
					display: flex;
					flex-direction: column;
					cursor: pointer;
					img {
						width: 7rem;
						height: 7rem;
						object-fit: contain;
						margin-top: 10px;
						background-color: #58514b;
						padding: 10px;
					}
					h4 {
						color: #747474;
						font-size: 14px;
						font-weight: 400;
					}
					h5 {
						font-size: 12px;
						color: #747474;
					}
					.viewpdf {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 7rem;
						height: 7rem;
						background-color: #58514b;
						border: 1px solid #58514b !important;
						color: #ffffff;
						font-size: 16px;
						border-radius: 5px;
						margin-top: 10px;
					}
				}

				.ant-checkbox-inner {
					border-color: #707070;
				}
				.ant-checkbox-checked .ant-checkbox-inner {
					background-color: #fe9600;
				}
			}
		}
		.add-kyc-action {
			display: flex;
			width: 90%;
			justify-content: space-around;
			button {
				width: 35%;
				margin-right: 2%;
				&:first-of-type {
					margin-right: 2%;
				}
			}
			.modal-button-loader {
				position: absolute;
				left: 70%;
			}
		}
		.dialog-title h2 {
			font-size: 24px !important;
		}
		.hr-line {
			border-bottom: solid 0.4px gray;
			margin: 15px 0;
		}
		.no-record {
			color: #747474;
			text-align: center;
		}
	}

