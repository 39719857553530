.bm-menu-wrap {
    top: 0;
    display: none!important;
}

.mobile-menu {
    position: fixed;
    height: 100%;
    z-index: 1050;
    top: 0px;
    left: 0px;
    padding: 1rem;
    background-color: rgb(65, 55, 46);
    overflow: hidden scroll;
    width: 16rem;
    box-shadow: rgb(80, 80, 80) 1px 1px 11px 1px;
    animation: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s 1 normal both running bcmaMZ;
    display: flex;
    flex-direction: column;
}

.mobile-menu a {
    color: white !important;
    marging-bottom: 10px;
    display: block;
    padding: 1rem;
    padding-top: 1rem;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    margin: 0;
}

.mobile-menu a:hover {
    color: #d2502d;
}

.wallet-popup-content {
    padding: 0 !important;
}

.wallet-diconnected-popup {
    display: none;
}

.disconnet-text {
    font: normal normal normal 13px/24px Lato !important;
    letter-spacing: 0.13px;
    color: #F60011 !important;
    margin: 0 5px !important;
    cursor: pointer;
}

.disconnet-div {
    border: none !important;
}

.balance-tex {
    text-align: left;
    font: normal normal normal 13px/24px Lato !important;
    letter-spacing: 0.13px;
    color: #777777;
}

.eth-text {
    text-align: left;
    font: normal normal 900 13px/24px Lato !important;
    letter-spacing: 0.33px;
    color: #000000;
}

.wallet-address-text {
    white-space: nowrap;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.web3modal-modal-lightbox {
    z-index: 1500 !important;
}

@media screen and (max-width: 400px) {
    .bm-menu-wrap {
        display: block!important;
    }
}

@media screen and (max-width: 600px) {
    .bm-menu-wrap {
        display: block!important;
    }
}